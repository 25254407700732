import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import cabrio from '../../../assets/vehicle-json/cabrio.json';
import kastenwagen from '../../../assets/vehicle-json/kastenwagen.json';
import kleinwagen from '../../../assets/vehicle-json/kleinwagen.json';
import kombi from '../../../assets/vehicle-json/kombi.json';
import limousine from '../../../assets/vehicle-json/limousine.json';
import sportwagen from '../../../assets/vehicle-json/sportwagen.json';
import suv from '../../../assets/vehicle-json/suv.json';
import van from '../../../assets/vehicle-json/van.json';
import { ActivatedRoute } from '@angular/router';
import { PathParams } from '../../app-routing-pathnames';
import jwtDecode from 'jwt-decode';
import { JwtToken } from '../../common/services/jwt-token';
import { environment } from '../../../environments/environment';
import { ModalSuccessComponent } from '../../common/modal-success/modal-success.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalFailureComponent } from '../../common/modal-failure/modal-failure.component';

@Component({
  selector: 'app-vehicle-selection-page',
  templateUrl: './vehicle-selection-page.component.html',
  styleUrls: ['./vehicle-selection-page.component.scss']
})
export class VehicleSelectionPageComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  phone = new FormControl('', [Validators.pattern('^\\(?\\+\\(?49\\)?[ ()]?([- ()]?\\d[- ()]?){10,15}$')]);
  vehicleSelection = new FormControl('', [Validators.required]);
  vehicleSelectionForm: FormGroup;
  submitted = false;
  loading = false;
  idToken: string;
  environment = environment;

  constructor(private route: ActivatedRoute, private http: HttpClient, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.vehicleSelectionForm = new FormGroup({
      vehicleSelection: this.vehicleSelection,
      email: this.email,
      phone: this.phone,
    });

    this.route.fragment.subscribe((fragment: string) => {
      const params = new URLSearchParams(fragment);
      this.idToken = params.get(PathParams.idToken);
      const token = jwtDecode<JwtToken>(this.idToken);
      this.email.setValue(token.email);
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.vehicleSelectionForm.valid) {
      return;
    }
    this.loading = true;
    let body;
    switch (this.vehicleSelection.value) {
      case 'cabrio':
        cabrio.customerProfile.email = this.email.value;
        cabrio.customerProfile.phoneMobile = this.phone.value;
        body = cabrio;
        break;
      case 'kastenwagen':
        kastenwagen.customerProfile.email = this.email.value;
        kastenwagen.customerProfile.phoneMobile = this.phone.value;
        body = kastenwagen;
        break;
      case 'kleinwagen':
        kleinwagen.customerProfile.email = this.email.value;
        kleinwagen.customerProfile.phoneMobile = this.phone.value;
        body = kleinwagen;
        break;
      case 'kombi':
        kombi.customerProfile.email = this.email.value;
        kombi.customerProfile.phoneMobile = this.phone.value;
        body = kombi;
        break;
      case 'limousine':
        limousine.customerProfile.email = this.email.value;
        limousine.customerProfile.phoneMobile = this.phone.value;
        body = limousine;
        break;
      case 'sportwagen':
        sportwagen.customerProfile.email = this.email.value;
        sportwagen.customerProfile.phoneMobile = this.phone.value;
        body = sportwagen;
        break;
      case 'suv':
        suv.customerProfile.email = this.email.value;
        suv.customerProfile.phoneMobile = this.phone.value;
        body = suv;
        break;
      case 'van':
        van.customerProfile.email = this.email.value;
        van.customerProfile.phoneMobile = this.phone.value;
        body = van;
        break;
    }
    const url = this.environment.API_BASE_PATH + '/dealer/carsale';
    const headers = new HttpHeaders({'Content-Type': 'application/json', Accept: 'application/json'});
    this.http.post( url, body, {headers}).subscribe({
      next: response => {
        this.loading = false;
        this.dialog.open(ModalSuccessComponent, { width: '550px', autoFocus: false,
          maxHeight: '90vh', panelClass: 'custom-modalbox'});
      },
      error: err => {
        this.loading = false;
        this.dialog.open(ModalFailureComponent, { width: '550px', autoFocus: false,
          maxHeight: '90vh', panelClass: 'custom-modalbox'});
      }
    });

  }
}
