import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { LoginPageComponent } from '../views/login-page/login-page.component';
import { SignupPageComponent } from '../views/signup-page/signup-page.component';
import { ModalSuccessComponent } from './modal-success/modal-success.component';
import { ModalFailureComponent } from './modal-failure/modal-failure.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoginPageComponent,
    SignupPageComponent,
    ModalSuccessComponent,
    ModalFailureComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoginPageComponent,
    SignupPageComponent,
    ModalSuccessComponent,
    ModalFailureComponent
  ],
  entryComponents: [
    ModalSuccessComponent,
    ModalFailureComponent
  ],
})
export class TvdCommonModule { }
