export interface PathName {
  [key: string]: Path;
}

export interface Path {
  url: string;
  name: string;
}

export const PathNames: PathName = {
  vehicleSelectionPage: { url: 'fahrzeugauswahl', name: 'Fahrzeugauswahl'},
  errorPage: { url: 'fehler', name: 'Fehler'}
};

export const PathParams = {
  idToken: 'id_token'
};
