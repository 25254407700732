import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { JwtToken } from './jwt-token';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  public authenticate(idToken: string): boolean {
    if (!idToken) {
      return false;
    }

    const decoded = jwtDecode<JwtToken>(idToken);
    const date = Date.parse(decoded.exp);

    if (decoded.aud !== environment.clientId) {
      return false;
    }

    if (date > Date.now()) {
      return false;
    }

    return true;
  }

}
