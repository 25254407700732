import {Component} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  signOutUrl: string;

  constructor() {
    this.signOutUrl = environment.signOutUrl;
  }

  onSignOut() {
    window.open(this.signOutUrl, '_self');
  }
}
