export const environmentBase = {
  production: false,
  API_BASE_PATH: 'http://localhost:8080',
  url: 'https://apps.tvd.cc-n.dev',
  subDomain: 'app-auth-dev',
  callbackUrl: 'http://localhost:4200/',
  clientId: '72ehirqsl7lqicj53us7iuateh',
  region: 'eu-central-1',
  authDomain: '',
  pathParams: '',
  signInUrl: '',
  signOutUrl: ''
};
