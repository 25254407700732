import { Config } from './config';
import { environmentBase } from './environment.base';

export const environment: Config = {
  ...environmentBase,
  production: false,
  API_BASE_PATH: 'https://backend.dev2.tvd.cc-n.dev',
  callbackUrl: 'https://apps.tvd.cc-n.dev',
  clientId: '4eutm8kudavjq9od4c5coocg9p',
};

environment.authDomain
  = 'https://'
  + environment.subDomain
  + '.auth.'
  + environment.region
  + '.amazoncognito.com';

environment.pathParams
  = '?client_id=' + environment.clientId
  + '&response_type=token'
  + '&redirect_uri=' + environment.callbackUrl;

environment.signInUrl
  = environment.authDomain
  + '/login'
  + environment.pathParams;

environment.signOutUrl
  = environment.authDomain
  + '/logout'
  + environment.pathParams;

