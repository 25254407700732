import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VehicleSelectionPageComponent } from './views/vehicle-selection-page/vehicle-selection-page.component';
import { PathNames } from './app-routing-pathnames';
import { AuthGuard } from './common/helpers/auth.guard';


const routes: Routes = [
  // login page: error handling for missing carSaleId
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: PathNames.vehicleSelectionPage.url,
        pathMatch: 'full'
      },
      {
        path: PathNames.vehicleSelectionPage.url,
        component: VehicleSelectionPageComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
