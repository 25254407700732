import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-failure',
  templateUrl: './modal-failure.component.html',
  styleUrls: ['./modal-failure.component.scss']
})
export class ModalFailureComponent {

  constructor(private dialogRef: MatDialogRef<ModalFailureComponent>) {
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
